'use client';

import React, { PropsWithChildren } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { bottomBarPortalID } from 'utils/portals';
import ConditionalBackground from '~/ui/conditional-bg';
import NavBarContent from '~/nav-bar/content';
import { NavBar } from '~/nav-bar/nav-bar';
import { useNavigationContext } from 'providers/bottom-navigation';

function LayoutContent({ children }: PropsWithChildren) {
  const { width } = useWindowSize();
  const mobile = width < 1024;

  return (
    <div className='flex flex-col w-screen h-screen overscroll-none'>
      <NavBar />
      {!mobile && <DesktopLayoutContent>{children}</DesktopLayoutContent>}
      {mobile && <MobileLayoutContent>{children}</MobileLayoutContent>}
      <ConditionalBackground />
    </div>
  );
}

function DesktopLayoutContent({ children }: PropsWithChildren) {
  const { navigationContent } = useNavigationContext();

  return (
    <div className='flex h-full overflow-hidden overscroll-none'>
      <div className='flex relative flex-col w-full h-full overflow-y-auto overflow-x-hidden overscroll-none'>
        {children}
        {navigationContent && (
          <nav className='sticky bottom-0 z-50 w-full p-4 border-t border-gray-800 bg-gray-950'>
            <div className='flex items-center justify-between w-full gap-4'>{navigationContent}</div>
          </nav>
        )}
        <div className='block mt-auto' id={bottomBarPortalID}></div>
      </div>
      <div className='px-4 border-l border-purple-700 bg-primary'>
        <div className='block h-full mt-auto'>
          <NavBarContent />
        </div>
      </div>
    </div>
  );
}

function MobileLayoutContent({ children }: PropsWithChildren) {
  const { navigationContent } = useNavigationContext();

  return (
    <div className='flex flex-col h-full'>
      <div className='flex-1 overflow-y-auto overscroll-none border-t border-gray-700'>{children}</div>
      {navigationContent && (
        <nav className='sticky bottom-0 z-50 w-full p-4 border-t border-gray-800 bg-gray-950'>
          <div className='flex items-center justify-between w-full gap-4'>{navigationContent}</div>
        </nav>
      )}
      <div className='block' id={bottomBarPortalID}></div>
    </div>
  );
}

export default LayoutContent;
