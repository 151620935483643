'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import React, { useEffect, useRef } from 'react';
import Link from 'next/link';
import { Sheet, SheetContent, SheetTrigger } from '../ui/sheet';
import { HamburgerMenuIcon } from '@radix-ui/react-icons';
import NavBarContent from './content';
import { useWindowSize } from 'usehooks-ts';
import { zeroAddress } from 'utils/constants';
import { useNavigationContext } from 'providers/bottom-navigation';

export function NavBar() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const address = searchParams.get('address') || zeroAddress;
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const { sheetOpen, setSheetOpen } = useNavigationContext();
  const prevPathRef = useRef(pathname);
  const prevWidthRef = useRef(width);

  useEffect(() => {
    if (pathname === '/') {
      window.history.replaceState(null, '', '/posts');
    }
  }, [pathname]);

  useEffect(() => {
    // Only close sheet if path actually changed
    if (pathname && pathname !== prevPathRef.current) {
      setSheetOpen(false);
      prevPathRef.current = pathname;
    }
  }, [pathname, setSheetOpen]);

  useEffect(() => {
    // Only close sheet if width changed from mobile to desktop
    if (prevWidthRef.current < 1024 && width >= 1024) {
      setSheetOpen(false);
    }
    prevWidthRef.current = width;
  }, [width, setSheetOpen]);

  const renderText = !pathname ? '' : pathname.replace('/', '');

  function headerText() {
    const text = renderText.split('/');
    if (isMobile) {
      return text[0];
    } else {
      return text.join(' / ');
    }
  }

  function computePathName() {
    if (pathname === '/identity') {
      return pathname + '?address=' + address;
    }
    return pathname;
  }

  return (
    <Sheet open={sheetOpen} onOpenChange={setSheetOpen}>
      <div className='flex justify-between overflow-hidden border-purple-600 lg:border-0 overscroll-none touch-none bg-primary'>
        <div className='flex items-center gap-2 p-2 py-4 text-lg uppercase twitterNoShadow'>
          <img className='w-7 h-7' src='https://immutable-cdn.s3.us-east-2.amazonaws.com/logo/token.svg' alt='IMT' />
          <Link href={'/'}>Immutable</Link>/
          <Link href={computePathName()} className='truncate'>
            {headerText()}
          </Link>
        </div>
        <SheetTrigger tabIndex={-1} className='pr-4 lg:hidden'>
          <HamburgerMenuIcon className='w-8 h-8' />
        </SheetTrigger>
      </div>
      <SheetContent className='h-full touch-none'>
        <NavBarContent />
      </SheetContent>
    </Sheet>
  );
}
