'use client';

import Providers from '../../providers/providers';
import LayoutContent from './layout-content';
import TutorialDialog from '~/tutorial/tutorial-dialog';

export default function ClientLayout({ children }: { children: React.ReactNode }) {
  return (
    <Providers>
      <LayoutContent>
        <TutorialDialog />
        {children}
      </LayoutContent>
    </Providers>
  );
}
