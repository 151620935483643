// In Next.js, this file would be called: app/providers.jsx
'use client';
import { Toaster } from 'react-hot-toast';

// Since QueryClientProvider relies on useContext under the hood, we have to put 'use client' on top
import { isServer, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import NextAdapterApp from 'next-query-params/app';
import { PropsWithChildren, Suspense } from 'react';
import { QueryParamProvider } from 'use-query-params';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { type ThemeProviderProps } from 'next-themes/dist/types';
import { WalletProvider } from './wallet';
import { ImmutableTokenContextProvider } from './immutable-token';
import { ImmutableCollectionContextProvider } from './immutable-collection';
import { IdentityContextProvider } from './identity';
import { AuthContextProvider } from './auth';
import { NavigationProvider } from './bottom-navigation';
//
function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // With SSR, we usually want to set some default staleTime
        // above 0 to avoid refetching immediately on the client
        staleTime: 60 * 1000,
      },
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;
//
function getQueryClient() {
  if (isServer) {
    // Server: always make a new query client
    return makeQueryClient();
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important, so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}
export function ThemeProvider({ children, ...props }: ThemeProviderProps) {
  return <NextThemesProvider {...props}>{children}</NextThemesProvider>;
}

export default function Providers({ children }: PropsWithChildren) {
  // NOTE: Avoid useState when initializing the query client if you don't
  //       have a suspense boundary between this and the code that may
  //       suspend because React will throw away the client on the initial
  //       render if it suspends and there is no boundary
  const queryClient = getQueryClient();

  return (
    <Suspense>
      <ThemeProvider attribute='class' enableSystem={false} defaultTheme='dark'>
        <WalletProvider>
          <QueryClientProvider client={queryClient}>
            <QueryParamProvider adapter={NextAdapterApp}>
              <AuthContextProvider>
                <ImmutableTokenContextProvider>
                  <ImmutableCollectionContextProvider>
                    <IdentityContextProvider>
                      <NavigationProvider>{children}</NavigationProvider>
                    </IdentityContextProvider>
                  </ImmutableCollectionContextProvider>
                </ImmutableTokenContextProvider>
              </AuthContextProvider>
              <Toaster
                toastOptions={{
                  duration: 1000,
                  style: {
                    borderRadius: '10px',
                    border: '2px solid #333',
                    background: '#020617',
                    color: '#FFF',
                    filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 1))',
                  },
                }}
              />
            </QueryParamProvider>
          </QueryClientProvider>
        </WalletProvider>
      </ThemeProvider>
    </Suspense>
  );
}
