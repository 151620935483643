import Link from 'next/link';
import { cn } from 'utils/utils';
import { Button } from '~/ui/button';

type NavMenuItemProps = {
  page: string;
  color: string;
  icon: React.ReactNode;
  selected: boolean;
  url?: string;
  className?: string;
  onClick?: () => void;
  target?: string;
};

function NavMenuItem({
  page,
  icon,
  selected,
  color,
  onClick,
  className,
  url = page,
  target = '_self',
}: NavMenuItemProps) {
  const sharedStyles =
    'flex h-fit items-center justify-start ring-0 py-4 overflow-hidden text-ellipsis p-2 shadow-black text-2xl py-4 shadow-md uppercase bg-transparent hover:text-purple-800 hover:bg-slate-900 hover:border-purple-900 transition-colors duration-200 ease-in-out gap-4 border border-purple-600 w-full';
  if (!onClick) {
    return (
      <Link
        href={`${url}`}
        target={target}
        style={{ filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 1))' }}
        className={cn(
          sharedStyles,
          `text-${color}-500`,
          selected ? 'bg-slate-950 border-purple-900 text-purple-800' : '',
        )}
      >
        {icon}
        <span className='overflow-hidden text-ellipsis'>{page}</span>
      </Link>
    );
  } else {
    return (
      <Button
        type='button'
        onClick={onClick}
        style={{ filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 1))' }}
        className={cn(
          sharedStyles,
          className,
          `text-${color}-500`,
          selected ? 'bg-slate-950 border-purple-900 text-purple-800' : '',
        )}
      >
        {icon}
        <span className='overflow-hidden text-ellipsis'>{page}</span>
      </Button>
    );
  }
}

export default NavMenuItem;
