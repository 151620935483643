'use client';
import React, { createContext, useContext, useState, useMemo, useCallback } from 'react';

type NavigationContextType = {
  navigationContent: React.ReactNode;
  setNavigationBarContent: (content: React.ReactNode) => void;
  sheetOpen: boolean;
  setSheetOpen: (open: boolean) => void;
  showGameNavigation: boolean;
};

const NavigationContext = createContext<NavigationContextType>({
  navigationContent: null,
  setNavigationBarContent: () => {},
  sheetOpen: false,
  setSheetOpen: () => {},
  showGameNavigation: false,
});

export const useNavigationContext = () => useContext(NavigationContext);

export const NavigationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useState({
    sheetOpen: false,
    navigationContent: null as React.ReactNode,
    showGameNavigation: false,
  });

  const setNavigationBarContent = useCallback((content: React.ReactNode) => {
    setState((prev) => {
      // If content is the same, don't update
      if (prev.navigationContent === content) {
        return prev;
      }

      const newShowGameNavigation = !!content;
      // If both content and showGameNavigation would be the same, don't update
      if (prev.navigationContent === content && prev.showGameNavigation === newShowGameNavigation) {
        return prev;
      }

      return {
        ...prev,
        navigationContent: content,
        showGameNavigation: newShowGameNavigation,
      };
    });
  }, []);

  const setSheetOpen = useCallback((open: boolean) => {
    setState((prev) => {
      if (prev.sheetOpen === open) {
        return prev;
      }
      return {
        ...prev,
        sheetOpen: open,
      };
    });
  }, []);

  const value = useMemo(
    () => ({
      navigationContent: state.navigationContent,
      setNavigationBarContent,
      sheetOpen: state.sheetOpen,
      setSheetOpen,
      showGameNavigation: state.showGameNavigation,
    }),
    [state, setNavigationBarContent, setSheetOpen],
  );

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};
