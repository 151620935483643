'use client';

import React, { useState, useEffect } from 'react';
import { Button } from '~/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogClose } from '~/ui/dialog';
import { Socials } from '~/ui/socials';
import Link from 'next/link';

const title = 'Welcome to Immutable!';
const subTitle = 'A permanent, autonomous, decentralized platform.';
const whitepaperURL = 'https://d10w0pjeoltyj8.cloudfront.net/whitepaper.pdf';
const help = 'For help, reach out to us on Discord, X, or Telegram.';

export default function PopupDialog() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.localStorage) {
      const lastShown = localStorage.getItem('popupLastShown');
      const now = new Date();
      if (lastShown) {
        const lastShownDate = new Date(lastShown);
        //@ts-ignore
        const daysDifference = Math.floor((now - lastShownDate) / (1000 * 60 * 60 * 24));

        if (daysDifference >= 7) {
          setIsOpen(true);
          //@ts-ignore
          localStorage.setItem('popupLastShown', now);
        }
      } else {
        setIsOpen(true);
        //@ts-ignore
        localStorage.setItem('popupLastShown', now);
      }
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription className='flex flex-col items-center justify-center gap-4'>
            {subTitle}
            <div>
              Want to learn more? Read our whitepaper:{' '}
              <Link className='rainbowText' href={whitepaperURL} target='_blank' rel='noreferrer'>
                here
              </Link>
            </div>
            <div>Join the Immutable Organization!</div>

            <Link className='rainbowText' href={'./buy'} target='_blank' rel='noreferrer'>
              Buy $IMT{' '}
            </Link>
            {help}
            <Socials />
          </DialogDescription>
        </DialogHeader>
        <DialogClose asChild>
          <Button onClick={handleClose}>Close</Button>
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
}
